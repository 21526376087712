
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseUser } from '@/types/users.types';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';

@Component
export default class UserAvatar extends Vue {
  @Prop({ type: Object, required: true }) readonly user!: BaseUser;
  @Prop({ type: String, default: 'below' }) readonly tooltipPlacement!:
    | 'below'
    | 'after'
    | 'before'
    | 'above';

  @Prop(Boolean) readonly disableTooltip!: boolean;
  @Prop(Boolean) readonly isOverflow!: boolean;
  @Prop(Boolean) readonly copy!: boolean;

  get colors() {
    return ['#40488d', '#e49b13', '#bc1077', '#f36633', '#008a00'];
  }

  get color() {
    if (this.isOverflow) {
      return 'var(--theme-lighter)';
    }
    const { mudId, email } = this.user;
    if (!mudId && !email) return undefined;
    const s = mudId || email;

    let hash = 0;
    if (s.length == 0) {
      return this.colors[0];
    }
    for (let i = 0; i < s.length; i++) {
      hash = (hash << 5) - hash + s.charCodeAt(i);
      hash = hash & hash;
    }

    return this.colors[Math.abs(hash % this.colors.length)];
  }

  get initials(): string {
    const user = this.user;
    let { firstName, lastName } = user;
    if (user.fullName) {
      const names = user.fullName.split(/\s+/);
      firstName = names[0];
      lastName = names[1];
      if (firstName && lastName) {
        return firstName[0] + lastName[0];
      }
      return user.fullName[0];
    }

    if (user.firstName && user.lastName) {
      return user.firstName[0] + user.lastName[0];
    }
    if (user.firstName) {
      return user.firstName[0];
    }
    if (user.lastName) {
      return user.lastName[0];
    }

    return '?';
  }

  get showTooltip(): boolean {
    return !!this.tooltipText && !this.disableTooltip;
  }

  get tooltipText() {
    const user = this.user;
    const name = user.fullName || `${user.firstName} ${user.lastName}`;
    const email = user.email;
    return `${name} (${email})`;
  }

  copyEmail() {
    if (!this.copy || !this.user.email) {
      return;
    }
    navigator.clipboard
      .writeText(this.user.email)
      .then(() => {
        openSuccessSnackbar.call(this, 'Copied user email address!');
      })
      .catch(() => {
        openErrorSnackbar.call(this, 'Could not copy user email!');
      });
  }
}
