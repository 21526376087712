/**
 * All of this taken from Vue Router source code since
 * I need to use it but it's not exposed through the package
 */
import Router, { Route, Location } from 'vue-router';
import Vue from 'vue';

const trailingSlashRE = /\/?$/;

function queryIncludes(current: Route['query'], target: Route['query']): boolean {
  for (const key in target) {
    if (!(key in current)) {
      return false;
    }
  }
  return true;
}

function isObjectEqual(a = {}, b = {}): boolean {
  // handle null value #1566
  if (!a || !b) return a === b;
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const aVal = a[key];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bVal = b[key];
    // check nested equality
    if (typeof aVal === 'object' && typeof bVal === 'object') {
      return isObjectEqual(aVal, bVal);
    }
    return String(aVal) === String(bVal);
  });
}

export function isSameRoute(a: Route, b: Route): boolean {
  if (a.path && b.path) {
    return (
      a.path.replace(trailingSlashRE, '') === b.path.replace(trailingSlashRE, '') &&
      a.hash === b.hash &&
      isObjectEqual(a.query, b.query)
    );
  } else if (a.name && b.name) {
    return (
      a.name === b.name &&
      a.hash === b.hash &&
      isObjectEqual(a.query, b.query) &&
      isObjectEqual(a.params, b.params)
    );
  } else {
    return false;
  }
}

export function isIncludedRoute(current: Route, target: Route): boolean {
  return (
    current.path
      .replace(trailingSlashRE, '/')
      .indexOf(target.path.replace(trailingSlashRE, '/')) === 0 &&
    (!target.hash || current.hash === target.hash) &&
    queryIncludes(current.query, target.query)
  );
}

export function removeQuery(ctx: Vue, queries: string[] | string, push = false) {
  const { $router: router, $route: route } = ctx;
  const query: Route['query'] = {};
  const toRemove = new Set(typeof queries === 'string' ? [queries] : queries);
  Object.keys(route.query).forEach((key: string): void => {
    if (!toRemove.has(key)) {
      query[key] = route.query[key];
    }
  });
  const newRoute: Route = {
    ...route,
    query,
  };
  if (push) {
    return router.push(newRoute as unknown as Location);
  } else {
    return router.replace(newRoute as unknown as Location);
  }
}

type RouteQuery = Route['query'][''];

export function updateQuery(ctx: Vue, key: string, value: RouteQuery, push = false) {
  const { $router, $route } = ctx;
  const query = { ...$route.query, [key]: value };
  const newRoute: Route = {
    ...$route,
    query,
  };
  if (push) {
    return $router.push(newRoute as unknown as Location);
  } else {
    return $router.replace(newRoute as unknown as Location);
  }
}

function isString(n: unknown): n is string {
  return typeof n === 'string';
}

export type RouteLike = { $route: { query: Location['query'] } };
export function getQuery(
  ctx: RouteLike,
  key: string,
  opts: { toArray: true; toNumber: true },
): number[];
export function getQuery(
  ctx: RouteLike,
  key: string,
  opts: { toArray: false; toNumber: true },
): number | undefined;
export function getQuery(
  ctx: RouteLike,
  key: string,
  opts: { toArray: true; toNumber: false },
): string[];
export function getQuery(
  ctx: RouteLike,
  key: string,
  opts?: { toArray: false; toNumber: false },
): string | undefined;
export function getQuery(
  ctx: RouteLike,
  key: string,
  opts: { toArray: boolean; toNumber: boolean } = { toArray: false, toNumber: false },
): string | string[] | number[] | number | void {
  const { $route: route } = ctx;
  const q = [route.query?.[key]].flatMap(n => n).filter(isString);
  if (opts.toArray) {
    if (opts.toNumber) {
      return q.map(Number);
    } else {
      return q;
    }
  }
  if (!q.length) {
    return;
  }
  if (opts.toNumber) {
    return Number(q[0]);
  }

  return q[0];
}

/**
 * remove spaces and replace with hyphens
 */
export function slugify(str: string): string {
  return str.toLowerCase().trim().replace(/ /g, '-');
}
