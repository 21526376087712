
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RpaOpsModule } from '@/store/om27.module';
import { getQuery, updateQuery } from '@/utils/routing';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { RouteNames } from '@/constants';
import { openErrorSnackbar } from '@/utils/components';
import { signOffDetailsBySignOffId } from '@/api/om27.api';
import { RawLocation, Route } from 'vue-router';

type VueNode = (Element | Vue) & { shadowRoot?: VueShadowRoot; $el: VueNode };
type VueShadowRoot = { adoptedStyleSheets: any } & ShadowRoot;
type VueRefs = { gskSelect: VueNode, gskTextField: VueNode };
type VueStyleSheet = { replaceSync: (styles: string) => void } & CSSStyleSheet;

@Component({
  components: {
    GTextfield,
  },
})
export default class BotSearch extends Vue {
  // slot name string prop
  @Prop({ type: String, default: 'actionItems' }) slotName!: string;

  needingActionValue: boolean = RpaOpsModule.showActionNeededOnly;

  search = '';
  searchScope = 'global';
  isSearching = false;

  async handleSearch() {
    RpaOpsModule.setSearchText(this.search);
    if (this.searchScope === 'signOffId') {
      this.isSearching = true;
      const route = await this.getSignOffRedirectRoute(this.search);
      if (route) {
        this.$router.push(route);
      }
      this.isSearching = false;
      return;
    }

    if (this.$route.name === RouteNames.RpaOpsSearch) {
      updateQuery(this, 'search', this.search).catch(() => null);
      updateQuery(this, 'searchScope', this.searchScope).catch(() => null);
      return;
    }

    this.$router.push({
      name: RouteNames.RpaOpsSearch,
      query: {
        search: this.search,
        searchTarget: this.$route.name,
        searchScope: this.searchScope,
      },
    });
  }

  async getSignOffRedirectRoute(signOffId: string): Promise<RawLocation | null> {
    try {
      const { data } = await signOffDetailsBySignOffId(signOffId);
      if (data.isSignoffClosed) {
        return {
          name: RouteNames.RpaOpsSignOffDetails,
          params: {
            logicalBotId: String(data.logicalBotId),
            signOffId: String(data.signOffId),
          },
        };
      }
      return {
        name: RouteNames.RpaOpsSignOff,
        params: {
          logicalBotId: String(data.logicalBotId),
        },
      };
    } catch (e: any) {
      let message = e?.response?.data?.message as string;
      if (!message) {
        message = e.message;
      }
      openErrorSnackbar.call(this, message);
      return null;
    }
  }

  onNeedingActionChange(e: any) {
    RpaOpsModule.setShowActionNeededOnly(e.target.checked);
  }

  onSearchScopeChange(e: any) {
    this.searchScope = e.target.value;
  }

  updated() {
    this.$log('BotSearch updated');
  }

  created() {
    this.$log('BotSearch created');
    const searchScope = getQuery(this, 'searchScope');
    if (searchScope) {
      this.searchScope = searchScope;
    }

    if (this.$route.name === RouteNames.RpaOpsSearch) {
      const query = getQuery(this, 'search', { toArray: false, toNumber: false });
      if (query) {
        this.search = query;
        this.handleSearch();
      }
    } else {
      this.search = '';
      RpaOpsModule.setSearchText(this.search);
    }
  }

  mounted() {
    const { gskSelect, gskTextField }: { gskSelect: VueNode, gskTextField: VueNode } = this.$refs as VueRefs;

    const styleConfig = [
      { node: gskSelect?.shadowRoot, styles: gskSelectStyleOverrides },
      { node: gskTextField?.$el.shadowRoot, styles: gskTextFieldStyleOverrides },
    ];

    styleConfig.forEach(({ node, styles }: { node?: VueShadowRoot, styles: string }) => {
      if (node) {
        const sheet = new CSSStyleSheet() as VueStyleSheet;
        sheet.replaceSync(styles);
        node.adoptedStyleSheets = [ ...node.adoptedStyleSheets, sheet ];
      }
    });
  }
}

const gskSelectStyleOverrides = `
  .mdc-select-wrapper {
    min-width: 140px;
  }

  .mdc-select__selected-text {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-width: auto !important;
    font-size: 14px !important;
    padding-right: 38px !important;
  }

  .mdc-notched-outline__notch {
    padding: 0px !important;
    width: 0px !important;
  }

  .mdc-notched-outline__trailing {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0.5px !important;
  }
`;

const gskTextFieldStyleOverrides = `
  .mdc-notched-outline__leading {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0.5px !important;
  }
`;
